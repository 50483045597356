import React, { FC } from 'react';
import styled from 'styled-components';

const StyledSearchSvg = styled.svg`
  position: absolute;
  bottom: 6px;
  width: 24px;
  height: 24px;
`;

export const StyledSearchIcon: FC = () => (
  <StyledSearchSvg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" version="1.1">
    <path
      stroke="#d9d9d9"
      d="M22 22l-8.334-8.334M15.5 9A6.284 6.284 0 0 1 9 15.505 6.505 6.505 0 1 1 15.5 9z"
    />
  </StyledSearchSvg>
);
