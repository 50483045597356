/* eslint-disable no-shadow */
export interface Media {
  defaultVehicleFrontView?: string;
  favoriteVehicleFrontView?: string;
  vehicleExteriorColor?: string;
}
export interface OnboardingStatus {
  onboardingStatus: {
    hasAddedVehicle: boolean;
    hasSelectedPartner: boolean;
  };
  media: Media;
  unavailableData: UnavailableData[];
}

// type UnavailableData = "partner" | "vuds" | "vvda_renderpictures" | "vvda_core";

export enum UnavailableData {
  vuds = 'vuds',
  partner = 'partner',
  vvdaRenderpictures = 'vvda_renderpictures',
  vvdaCore = 'vvda_core',
  pvds = 'pvds',
}

export const fetchOnboardingStatus = async (
  accessToken: string,
  bffBaseUrl: string,
  locale: string,
): Promise<OnboardingStatus> => {
  const path = 'onboarding-status';
  const url = `${bffBaseUrl}/${path}`;

  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Accept-Language': locale,
    },
  });

  if (!response.ok) {
    throw new Error('Something went wrong');
  }

  return response.json() as Promise<OnboardingStatus>;
};
