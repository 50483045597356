import { UseI18nMessage } from '@oneaudi/i18n-context';

export const i18nScope = 'fa.add-dealer-teaser';

const i18nScopeList = ['button'] as const;

export type I18nKey = (typeof i18nScopeList)[number];

export const messages = () =>
  i18nScopeList.reduce(
    (acc, it) => ({
      ...acc,
      [it]: {
        id: `${i18nScope}.${it}`,
      },
    }),
    {} as Record<I18nKey, UseI18nMessage>,
  );
