import React, { FC } from 'react';
import styled from 'styled-components';

const StyledGpsSvg = styled.svg`
  right: 0;
  bottom: 3px;
  width: 24px;
  height: 24px;
  transition: color 0.25s cubic-bezier(0.75, 0.02, 0.5, 1);
`;

export const StyledGpsIcon: FC = () => (
  <StyledGpsSvg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" version="1.1">
    <path
      fill="none"
      stroke="#d9d9d9"
      d="M11.5 17v6m.003-23v6M23 11.5h-6m-11 0H0m20.5.02a9 9 0 1 1-9-9.02 8.99 8.99 0 0 1 9 9.02z"
    />
  </StyledGpsSvg>
);
