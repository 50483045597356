import {
  GoogleAddressComponent,
  GoogleAddressTypes,
  GooglePlaceTypes,
  GeocoderResult,
  ValidAddress,
} from './types';

// TODO: Write test + documentation for this function (unless its soon redundant?)
export function destructureAddress(geocodedAddress: GeocoderResult[]): ValidAddress {
  const hasAddress = Array.isArray(geocodedAddress) && geocodedAddress.length > 0;

  const addressType =
    hasAddress &&
    (geocodedAddress[0].types.length > 1 ? geocodedAddress[0].types : geocodedAddress[0].types[0]);

  const arrAddress = hasAddress ? geocodedAddress[0].address_components : [];
  let address = '';
  let city = '';
  let country = '';
  let district = '';
  let streetName = '';
  let streetNumber = '';
  let postalCode = '';

  function formulateAddress() {
    if (streetName && streetNumber) {
      address = `${streetName} ${streetNumber}`;
    }
  }

  const tArrAddress = arrAddress as unknown as GoogleAddressComponent[];

  tArrAddress.forEach((addressComponent: GoogleAddressComponent) => {
    const { types } = addressComponent;
    const output = addressComponent.long_name;

    // Fetch address
    if (!address) {
      if (
        addressType === GoogleAddressTypes.streetAddress ||
        addressType === GoogleAddressTypes.premise
      ) {
        if (!streetName && types.includes(GooglePlaceTypes.route)) {
          streetName = output;
          formulateAddress();
        }
        if (!streetNumber && types.includes(GooglePlaceTypes.streetNumber)) {
          streetNumber = output;
          formulateAddress();
        }
      } else if (addressType === GoogleAddressTypes.route) {
        if (types.includes(GooglePlaceTypes.route)) {
          address = output;
        }
      } else if (
        Array.isArray(addressType) &&
        (addressType.includes(GooglePlaceTypes.pointOfInterest) ||
          addressType.includes(GooglePlaceTypes.landmark) ||
          addressType.includes(GooglePlaceTypes.townSquare))
      ) {
        if (
          types.includes(GooglePlaceTypes.pointOfInterest) ||
          types.includes(GooglePlaceTypes.landmark) ||
          types.includes(GooglePlaceTypes.townSquare) ||
          types.includes(GooglePlaceTypes.route)
        ) {
          address = output;
        }
      }
    }

    // Fetch city
    if (
      !city &&
      (types.includes(GooglePlaceTypes.locality) || types.includes(GooglePlaceTypes.postalTown))
    ) {
      city = output;
    }

    // Fetch postal code
    if (!postalCode && types.includes(GooglePlaceTypes.postalCode)) {
      postalCode = output;
    }

    // Fetch district or city name for some cases
    if (!district && types.includes(GooglePlaceTypes.administrativeAreaLevel1)) {
      district = output;
    }

    // Fetch country
    if (!country && types.includes(GooglePlaceTypes.country)) {
      country = addressComponent.short_name; // Alpha code for country
    }
  });

  //   Final check for city (edge case incase none found, needed for certain countries)
  if (address && country && !city) {
    let fetchCity = geocodedAddress[0].address_components.filter((obj) => {
      return obj.types.includes(GooglePlaceTypes.sublocality);
    });
    if (fetchCity.length > 0) city = fetchCity[0].long_name;
    else {
      fetchCity = geocodedAddress[0].address_components.filter((obj) => {
        return obj.types.includes(GooglePlaceTypes.administrativeAreaLevel2);
      });
      if (fetchCity.length > 0) city = fetchCity[0].long_name;
    }
  }
  return {
    address,
    postalCode,
    district,
    city,
    country,
  };
}
