import React, { FC } from 'react';
import styled from 'styled-components';

const StyledIcon = styled.svg`
  fill: none;
`;

export const ArrowIcon: FC = () => (
  <StyledIcon width="20" height="26" viewBox="0 0 20 26" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 13L6 0H0L11.5 13H20Z" fill="#3C3C3C" />
    <path d="M20 13L6 26L0 26L11.5 13L20 13Z" fill="#3C3C3C" />
  </StyledIcon>
);
