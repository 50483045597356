import styled from 'styled-components';
import { Theme } from '@audi/audi-ui-react';

export const Container = styled.div`
  background: var(${({ theme }: { theme: Theme }) => theme.colors.ui.inverted});

  @media (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.m}px) {
    display: flex;
    flex-direction: row-reverse;
  }
`;
export const ImageBox = styled.div`
  @media (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.m}px) {
    flex: 0 0 50%;
  }
`;
export const StyledImg = styled.img`
  display: block;
  width: 100%;
  height: 370px;
  object-fit: cover;

  @media (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.s}px) {
    height: auto;
  }

  @media (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.m}px) {
    height: 590px;
  }

  @media (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.l}px) {
    height: 768px;
  }

  @media (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.xl}px) {
    height: 948px;
  }

  @media (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.xxl}px) {
    height: 1080px;
  }
`;

export const StyledCarImg = styled(StyledImg)`
  object-fit: contain;

  @media (max-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.m}px) {
    height: 375px;
  }
`;

export const StyledBlockSpan = styled.span`
  display: block;
`;

StyledBlockSpan.displayName = 'StyledBlockSpan';

export const TextBox = styled.div`
  padding: var(${({ theme }: { theme: Theme }) => theme.responsive.spacing.xxl});

  ul {
    padding-inline-start: var(${({ theme }: { theme: Theme }) => theme.responsive.spacing.m});
    padding-left: 0;
    margin-bottom: 0;
  }

  li {
    color: #3c3c3c;
    list-style-type: none;
    position: relative;
    margin-bottom: var(${({ theme }: { theme: Theme }) => theme.responsive.spacing.xs});
    display: flex;
  }

  li svg {
    width: 5px;
    margin-right: 16px;
  }

  @media (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.m}px) {
    align-self: center;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
`;
