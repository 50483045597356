import React, { useRef, RefObject } from 'react';
import { Logger } from '@feature-hub/core';
import { AuthServiceProviderV3 } from '@oneaudi/audi-auth-service';
import { I18NServiceV1 } from '@oneaudi/i18n-service';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import { EnvConfigServiceV1 } from '@oneaudi/audi-env-config-service';

import { Dependencies } from '../FeatureHubAppDefinition';

interface State {
  readonly authServiceProvider: AuthServiceProviderV3;
  readonly logger?: Logger;
  readonly featureAppGroup: string;
  readonly i18nService: I18NServiceV1;
  readonly inViewRef: RefObject<HTMLDivElement>;
  readonly localeService: LocaleServiceV1;
  readonly trackingService?: TrackingServiceV2;
  readonly vueFormatterService: VueFormatterServiceInterfaceV1;
  readonly envConfigService: EnvConfigServiceV1;
}

interface ContextValue {
  readonly featureServices: Dependencies;
  readonly children: JSX.Element;
  readonly featureAppGroup: string;
}

export const Context = React.createContext<State>({} as State);

export const ContextProvider = ({
  featureServices,
  children,
  featureAppGroup,
}: ContextValue): JSX.Element => {
  const {
    'vw:authService': authServiceProvider,
    'dbad:audi-i18n-service': i18nService,
    's2:logger': logger,
    'locale-service': localeService,
    'audi-tracking-service': trackingService,
    'dbad:audi-vue-formatter-service': vueFormatterService,
    'audi:envConfigService': envConfigService,
  } = featureServices;

  const inViewRef = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const state: State = {
    authServiceProvider,
    featureAppGroup,
    i18nService,
    inViewRef,
    localeService,
    logger,
    vueFormatterService,
    envConfigService,
    trackingService,
  };

  return (
    <Context.Provider value={state}>
      <div ref={inViewRef}>{children}</div>
    </Context.Provider>
  );
};
