import styled from 'styled-components';
import { Text, TextField, Theme } from '@audi/audi-ui-react';
import {
  COLOR_BASE_BRAND_BLACK,
  COLOR_BASE_GREY_10,
  COLOR_BASE_GREY_5,
  COLOR_BASE_GREY_15,
  COLOR_BASE_GREY_70,
  BREAKPOINT_S,
  SPACING_XS_L,
  SPACING_M_XS,
} from '@audi/audi-ui-design-tokens';

type GeolocationButtonTypes = {
  clickable: boolean;
};

export const SingleSuggestion = styled.div`
  padding: ${SPACING_XS_L}px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  &:hover {
    background: ${COLOR_BASE_GREY_10};
  }
`;

export const Copy = styled(Text)`
  display: inline-block;
  color: ${COLOR_BASE_BRAND_BLACK};
`;

export const BoldCopy = styled(Text)`
  display: inline-block;
  color: ${COLOR_BASE_BRAND_BLACK};
  font-weight: bold;
`;

export const GoogleLogo = styled.img`
  width: 144px;
  height: 18px;
`;

export const SuggestionsWrapper = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  background: ${COLOR_BASE_GREY_5};
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
  width: 100%;
  overflow-x: hidden;
  .googleLogo {
    align-self: flex-end;
    margin: ${SPACING_M_XS}px ${SPACING_M_XS}px 0;
  }
`;

export const SearchWrapper = styled.div`
  flex: auto;
  label {
    padding-left: 37px;
  }
  input {
    padding-left: 35px;
    padding-right: 35px;
  }
  @media (max-width: ${BREAKPOINT_S}px) {
    position: relative;
  }
`;

export const GeolocationButton = styled.div`
  position: absolute;
  right: 0;
  bottom: 2px;
  color: ${COLOR_BASE_GREY_15};
  cursor: pointer;
  &:hover {
    svg {
      color: ${COLOR_BASE_GREY_70};
    }
  }
  &:active {
    svg {
      color: ${COLOR_BASE_GREY_10};
    }
  }

  ${(props: GeolocationButtonTypes) =>
    props.clickable ? 'pointer-events: all;' : 'pointer-events: none;'}
`;

GeolocationButton.displayName = 'GeolocationButton';

export const LocationPickerWrapper = styled.div`
  display: flex;
  position: relative;
  flex-flow: row;
  margin: 32px 0;
  max-width: 500px;
`;

export const StyledTextField = styled(TextField)`
  @media (max-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.m}px) {
    max-width: 100%;
  }
`;
