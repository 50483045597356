import * as React from 'react';

export interface AsyncScriptLoaderProps {
  src: string | string[];

  onLoadError?(error: ErrorEvent): void;
  onLoadSuccess?(): void;
}

export class AsyncScriptLoader extends React.PureComponent<AsyncScriptLoaderProps> {
  protected static loadScript(url: string): Promise<unknown> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.onload = () => {
        resolve(url);
      };
      script.onerror = (error) => {
        reject(error);
      };
      script.async = true;
      script.defer = true;
      script.src = url;
      document.body.appendChild(script);
    });
  }

  public componentDidMount(): void {
    const { src } = this.props;
    const urls = typeof src === 'string' ? [src] : src;

    const loadedScripts = Array.from(document.scripts).map((script) => script.src);

    const isNewScript = (url: string) =>
      !loadedScripts.find((script: string) => script.endsWith(url));

    Promise.all(
      urls
        .filter(isNewScript)
        .map((url: string): Promise<unknown> => AsyncScriptLoader.loadScript(url)),
    )
      .then(() => {
        // eslint-disable-next-line react/destructuring-assignment,no-unused-expressions
        this.props.onLoadSuccess && this.props.onLoadSuccess();
      })
      .catch((error: ErrorEvent) => {
        // eslint-disable-next-line react/destructuring-assignment,no-unused-expressions
        this.props.onLoadError && this.props.onLoadError(error);
      });
  }

  public render(): null {
    return null;
  }
}
