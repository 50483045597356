import React from 'react';
import { ButtonGroup, Button, Text } from '@audi/audi-ui-react';
import { addWidthParameter, useContent, useInViewEffect } from '@oneaudi/feature-app-utils';

import { useTrackingManager } from '../context/useTrackingManager';
import { Context } from '../context';
import { Content } from '../EditorContentTypes';

import { ImageWidth, toSrcSet } from './utils/url-utils';
import LocationPicker, { ValidAddress } from './location-picker';
import { PersonalisedImageProps, PersonalisedMedia } from './CompontentTypes';
import { ImageBox, StyledCarImg, Container, StyledImg, TextBox } from './styles';
import { ArrowIcon } from './Icons/ArrorwIcon';
import { useTranslations } from '../hooks/useTranslations';
import { mapImages } from '../utils/mapImages';

export const PersonalisedImage: React.FunctionComponent<PersonalisedImageProps> = ({
  image,
  backgroundColor,
}: PersonalisedImageProps) => (
  <ImageBox style={{ backgroundColor }}>
    <picture>
      <source media="(min-width:1920px)" srcSet={image} data-testid="imageXXL" />
      <source media="(min-width:768px)" srcSet={`${image}?wid=1920`} data-testid="imageM" />
      <source media="(min-width:480px)" srcSet={`${image}?wid=1024`} data-testid="imageS" />
      <StyledCarImg srcSet={image} src={image} alt="favorite-vehicle" data-testid="image" />
    </picture>
  </ImageBox>
);

const formattedLocationInitialState: ValidAddress = {
  address: '',
  city: '',
  district: '',
  postalCode: '',
  country: '',
};

interface GeolocationError {
  code: number;
  message: string;
  PERMISSION_DENIED: number;
  POSITION_UNAVAILABLE: number;
  TIMEOUT: number;
}

interface DealerEntryTeaserProps {
  readonly linkTracking?: (href: string, text: string, clickId: string) => void;
  readonly personalisedMedia?: PersonalisedMedia;
}

export const DealerEntryTeaser: React.FunctionComponent<DealerEntryTeaserProps> = ({
  linkTracking,
  personalisedMedia,
}: DealerEntryTeaserProps) => {
  const content = useContent<Content>();
  const translations = useTranslations();
  const trackingManager = useTrackingManager();
  const contentTitle = useContent<Content>()?.defaultContent.texts.title;
  const [address, setAddress] = React.useState<string>('');
  const [formattedAddr, setFormattedAddr] = React.useState<ValidAddress>(
    formattedLocationInitialState,
  );
  const { localeService } = React.useContext(Context);
  const updateAddress = (value: string): void => {
    setAddress(value);
  };

  if (!content) {
    return null;
  }

  const image = mapImages(content.defaultContent.image);
  const { texts } = content.defaultContent;

  const handleValidAddress = (formatted: ValidAddress) => {
    setFormattedAddr(formatted);
  };

  const handleGeoLocationError = (error: GeolocationError): void => {
    if (error.code === 1) {
      // Error code 1 is when user blocks GPS or device has no GPS TODO smth with it
    }
  };

  const resultAddr = () => {
    const addSpace = (val: string) => (val ? `${val}` : '');
    const { address: resultAddrAddress, city, postalCode } = formattedAddr;
    return addSpace(resultAddrAddress) + addSpace(city) + addSpace(postalCode);
  };

  const countryCode = formattedAddr.country || localeService?.countryCode;
  const linkToDealerSearch = `${content.buttons.partnerSearchButtonUrl}?code=${countryCode}&addr=${resultAddr()}`;

  useInViewEffect(() => {
    trackingManager.trackImpression(contentTitle || '');
  });

  return (
    <Container>
      {personalisedMedia ? (
        <PersonalisedImage
          image={personalisedMedia?.vehicleFrontView}
          backgroundColor={personalisedMedia?.vehicleExteriorColor}
        />
      ) : (
        <ImageBox>
          <picture>
            <source
              media="(min-width:1920px)"
              srcSet={toSrcSet(image.xxl, ImageWidth.XXL)}
              data-testid="imageXXL"
            />
            <source
              media="(min-width:768px)"
              srcSet={toSrcSet(image.m, ImageWidth.M)}
              data-testid="imageM"
            />
            <StyledImg
              srcSet={toSrcSet(image.xs, ImageWidth.XS)}
              src={addWidthParameter(image.xs, ImageWidth.XS)}
              alt={image.altText}
              data-testid="image"
            />
          </picture>
        </ImageBox>
      )}

      <TextBox>
        <Text as="h3" variant="order2" spaceStackEnd="l">
          {texts.title}
        </Text>
        <Text as="p" variant="copy1">
          {texts.description}
        </Text>
        <ul>
          <li>
            <ArrowIcon />
            <Text as="p" variant="copy1">
              {texts.item1}
            </Text>
          </li>
          <li>
            <ArrowIcon />
            <Text as="p" variant="copy1">
              {texts.item2}
            </Text>
          </li>
          <li>
            <ArrowIcon />
            <Text as="p" variant="copy1">
              {texts.item3}
            </Text>
          </li>
        </ul>

        <LocationPicker
          googleChannelId="AUDI_GLOBAL_MYAUDI2"
          googleApiKey={process.env.GOOGLE_API_KEY}
          region="DE"
          language="de"
          onChange={updateAddress}
          label={texts.placeholder}
          value={address}
          enableGeoLocation
          onGeoLocationError={handleGeoLocationError}
          onValidAddress={handleValidAddress}
        />
        <ButtonGroup variant="block-buttons" spaceStackStart="xl">
          <Button
            variant="secondary"
            href={linkToDealerSearch}
            data-testid="partner-search"
            onClick={() =>
              linkTracking && linkTracking(linkToDealerSearch, translations.button, '')
            }
          >
            {translations.button}
          </Button>
        </ButtonGroup>
      </TextBox>
    </Container>
  );
};
