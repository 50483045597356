import * as React from 'react';

import { LocationPickerSingleSuggestion } from './single-suggestion';
import { Suggestion, BaseSuggestionTypes } from './types';
import { SuggestionsWrapper } from './styles';
import { GoogleLogoComponent } from './google-logo';

type props = BaseSuggestionTypes & {
  suggestions: Suggestion[] | null;
};

export const LocationPickerSuggestionsWrapper: React.FunctionComponent<props> = ({
  suggestions,
  query,
  handleClick,
}) => {
  if (!suggestions) {
    return null;
  }

  return (
    <SuggestionsWrapper>
      <GoogleLogoComponent />
      {suggestions &&
        suggestions.map((suggestion: Suggestion, index: number) => {
          return (
            <LocationPickerSingleSuggestion
              suggestion={suggestion}
              // eslint-disable-next-line react/no-array-index-key
              key={`location-picker-suggestion-${index}`}
              query={query}
              handleClick={handleClick}
            />
          );
        })}
    </SuggestionsWrapper>
  );
};
