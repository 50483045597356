import React from 'react';
import { ReactFeatureApp } from '@feature-hub/react';
import { FeatureAppDefinition, FeatureServices, Logger } from '@feature-hub/core';
import { ContentServiceV1 } from '@oneaudi/content-service';
import { I18NServiceV1 } from '@oneaudi/i18n-service';
import { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { ContentContextProvider } from '@oneaudi/feature-app-utils';
import { AuthServiceProviderV3 } from '@oneaudi/audi-auth-service';
import { EnvConfigServiceV1 } from '@oneaudi/audi-env-config-service/dist/v1/EnvConfigServiceV1';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import I18nContextComponent from '@oneaudi/i18n-context';
import { AuthContextComponent, EnvConfigContextComponent } from '@oneaudi/myaudi-kit-utils';

import { ContextProvider } from './context';
import { i18nScope } from './i18n';
import FeatureApp from './FeatureApp';
import { EnvConfig } from './types';

export interface Dependencies extends FeatureServices {
  readonly 's2:logger': Logger;
  readonly 'audi-content-service': ContentServiceV1;
  readonly 'dbad:audi-i18n-service': I18NServiceV1;
  readonly 'audi-tracking-service'?: TrackingServiceV2;
  readonly 'dbad:audi-vue-formatter-service': VueFormatterServiceInterfaceV1;
  readonly 'locale-service': LocaleServiceV1;
  readonly 'vw:authService': AuthServiceProviderV3;
  readonly 'audi:envConfigService': EnvConfigServiceV1;
}

export interface Config {
  readonly featureAppGroup?: string;
}

const MyAudiTeaserFeatureAppDefinition: FeatureAppDefinition<
  ReactFeatureApp,
  Dependencies,
  Config
> = {
  dependencies: {
    externals: {
      react: '*',
      'react-dom': '*',
      'styled-components': '*',
      '@audi/audi-ui-react': '*',
      '@feature-hub/react': '5.3.11',
    },
    featureServices: {
      'audi-content-service': '^1.0.0',
      'dbad:audi-i18n-service': '^1.0.0',
      'locale-service': '^1.0.0',
      'vw:authService': '^2.0.0',
      'audi:envConfigService': '^1.0.0',
    },
  },
  optionalDependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      'audi-tracking-service': '^1.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'dbad:audi-vue-formatter-service': '^1.0.0',
    },
  },

  create: ({ config, featureServices }) => {
    const featureAppGroup = config?.featureAppGroup || 'myaudi';
    const {
      'audi-content-service': contentService,
      'audi-tracking-service': trackingService,
      'locale-service': localeService,
      's2:logger': logger,
      'dbad:audi-i18n-service': i18nService,
    } = featureServices;
    const envConfigServiceV1 = featureServices['audi:envConfigService'];
    const authServiceProvider = featureServices['vw:authService'];

    logger?.info('Feature App created.');

    if (trackingService) {
      // set feature app name as early as possible
      trackingService.featureAppName = 'audi-feature-app-dealer-entry-teaser';
    }

    const authService = authServiceProvider.register(featureAppGroup);

    return {
      render: () => (
        <EnvConfigContextComponent<EnvConfig>
          envConfigKeys={['add-dealer-teaser']}
          envConfigService={envConfigServiceV1}
          canThrowError
        >
          <AuthContextComponent authService={authService}>
            <ContentContextProvider contentService={contentService}>
              <ContextProvider featureServices={featureServices} featureAppGroup={featureAppGroup}>
                <I18nContextComponent
                  i18nData={i18nService}
                  language={localeService.language}
                  scopes={[i18nScope]}
                >
                  <FeatureApp />
                </I18nContextComponent>
              </ContextProvider>
            </ContentContextProvider>
          </AuthContextComponent>
        </EnvConfigContextComponent>
      ),
    };
  },
};

export default MyAudiTeaserFeatureAppDefinition;
