import * as React from 'react';

import { Suggestion, BaseSuggestionTypes } from './types';
import { SingleSuggestion, Copy, BoldCopy } from './styles';

type LocationPickerSingleSuggestionProps = BaseSuggestionTypes & {
  suggestion: Suggestion;
};

export const LocationPickerSingleSuggestion: React.FC<LocationPickerSingleSuggestionProps> = (
  props,
) => {
  const { suggestion, query, handleClick } = props;

  const queryMatchesResult =
    // If query matches the start of the result, the bit matching the query should be bold
    suggestion.description?.substring(0, query.length).toLowerCase() === query.toLowerCase();

  return (
    // eslint-disable-next-line react/jsx-no-bind
    <SingleSuggestion onMouseDown={handleClick.bind(this, suggestion)}>
      {queryMatchesResult ? (
        <>
          <BoldCopy>{suggestion?.description.substring(0, query.length)}</BoldCopy>
          <Copy>{suggestion?.description.substring(query.length)}</Copy>
        </>
      ) : (
        <Copy>{suggestion.description}</Copy>
      )}
    </SingleSuggestion>
  );
};
