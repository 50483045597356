export type Suggestion = google.maps.places.AutocompletePrediction;
export type GeocoderResult = google.maps.GeocoderResult;

export type BaseSuggestionTypes = {
  query: string;
  handleClick: (suggestion: Suggestion) => void;
};

export enum GoogleAddressTypes {
  'streetAddress' = 'street_address',
  'route' = 'route',
  'premise' = 'premise',
}

export enum GooglePlaceTypes {
  'townSquare' = 'town_square',
  'landmark' = 'landmark',
  'pointOfInterest' = 'point_of_interest',
  'route' = 'route',
  'streetNumber' = 'street_number',
  'locality' = 'locality',
  'postalTown' = 'postal_town',
  'postalCode' = 'postal_code',
  'sublocality' = 'sublocality',
  'administrativeAreaLevel1' = 'administrative_area_level_1',
  'administrativeAreaLevel2' = 'administrative_area_level_2',

  'country' = 'country',
}

export type GoogleAddressComponent = {
  // eslint-disable-next-line camelcase
  long_name: string;
  // eslint-disable-next-line camelcase
  short_name: string;
  types: GooglePlaceTypes[];
};

export type ValidAddress = {
  address: string;
  postalCode: string;
  district: string;
  city: string;
  country: string;
};

export interface Geolocation {
  coords: {
    latitude: number;
    longitude: number;
    altitude: number | null;
    accuracy: number;
    altitudeAccuracy: number | null;
    heading: number | null;
    speed: number | null;
  };
  timestamp: number;
}

export interface GeolocationError {
  code: number;
  message: string;
  PERMISSION_DENIED: number;
  POSITION_UNAVAILABLE: number;
  TIMEOUT: number;
}
