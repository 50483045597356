import React from 'react';

import { Context } from './index';

export interface TrackingManager {
  trackReady(version: string, text: string): void;
  trackClick(targetURL: string, label: string, clickID: string, text: string): void;
  trackImpression(text: string): void;
}

export interface TrackingEvent {
  event: {
    eventInfo: {
      eventAction: string;
      eventName: string;
    };
    attributes: Record<string, unknown>;
  };
}

function createTrackingPayload(
  action: string,
  name: string,
  attributes: Record<string, unknown>,
): TrackingEvent {
  const { host, pathname } = document.location;
  const defaultAttributes = {
    componentName: 'dealer-search-entry-teaser',
    currentURL: `${host}${pathname}`,
    label: '',
    targetURL: '',
    clickID: '',
    elementName: '',
    value: '',
    pos: '',
  };

  return {
    event: {
      eventInfo: {
        eventAction: action,
        eventName: name,
      },
      attributes: {
        ...defaultAttributes,
        ...attributes,
      },
    },
  };
}

function createComponentPayload(version: string) {
  return {
    componentInfo: {
      implementer: 2,
      version,
    },
  };
}

export function useTrackingManager(): TrackingManager {
  const { trackingService, logger } = React.useContext(Context);
  return React.useMemo((): TrackingManager => {
    function track(...args: Parameters<typeof createTrackingPayload>): void {
      if (trackingService) {
        const payload = createTrackingPayload(...args);
        if (logger) {
          logger.debug('Sending tracking event: ', payload);
        }
        trackingService.track(payload);
      }
    }

    function trackWithComponent(
      version: string,
      ...args: Parameters<typeof createTrackingPayload>
    ): void {
      if (trackingService) {
        const payload = createTrackingPayload(...args);

        if (logger) {
          logger.debug('Sending tracking event: ', payload);
        }

        const componentPayload = createComponentPayload(version);
        if (logger) {
          logger.debug('Sending component info', componentPayload);
        }

        // TODO update tracking service with componentPayload as second argument
        // when the service has been updated
        trackingService.track(payload);
      }
    }

    return {
      trackReady(version, text) {
        trackWithComponent(
          version,
          'feature_app_ready',
          'dealer search - entry teaser - feature app ready',
          {
            value: text,
          },
        );
      },

      trackClick(targetURL, label, clickID, text) {
        const checkLinkTarget = (url: string) => {
          const tempLink = document.createElement('a');
          tempLink.href = url;

          if (tempLink.hostname === window.location.hostname) {
            return 'internal_link';
          }

          return 'exit_link';
        };

        track(checkLinkTarget(targetURL), 'dealer search - entry teaser - click', {
          label,
          elementName: 'button',
          targetURL,
          clickID,
          value: text,
        });
      },

      trackImpression(text) {
        track('impression', 'dealer search - entry teaser - impression', {
          value: text,
        });
      },
    };
  }, [trackingService]);
}
