import { getImageUrls } from '@oneaudi/feature-app-utils';

import { ImageProps as ComponentImageProps } from '../components/CompontentTypes';
import { ImageProps } from '../EditorContentTypes';

export const mapImages = (image: ImageProps): ComponentImageProps => {
  return {
    xs: getImageUrls(image.xs.assetValue)[0],
    m: getImageUrls(image.m.assetValue)[0],
    xxl: getImageUrls(image.xxl.assetValue)[0],
    altText: image.altText,
  };
};
