import React from 'react';

import { InViewContextProvider } from '@oneaudi/feature-app-utils';
import { audiDarkTheme, ThemeProvider } from '@audi/audi-ui-react';
import { App } from './components/App';
import { useEnvConfig } from './hooks/useEnvConfig';

const FeatureApp: React.FunctionComponent = () => {
  const envConfig = useEnvConfig();

  // we have to wait until env config is loaded
  if (!envConfig) {
    return null;
  }

  return (
    <ThemeProvider theme={audiDarkTheme}>
      <InViewContextProvider>
        <App />
      </InViewContextProvider>
    </ThemeProvider>
  );
};

export default FeatureApp;
