import * as React from 'react';
import { Loader } from '@audi/audi-ui-react';

import { AsyncScriptLoader } from '../utils/asyncScriptLoader';
import { StyledGpsIcon } from '../Icons/GpsIcon';
import { StyledSearchIcon } from '../Icons/SearchIcon';

import { LocationPickerSuggestionsWrapper } from './suggestions-wrapper';
import { Suggestion, ValidAddress, GeolocationError, Geolocation } from './types';
import { destructureAddress } from './destructureAddress';
import { SearchWrapper, GeolocationButton, LocationPickerWrapper, StyledTextField } from './styles';

interface LocationPickerProps {
  value?: string;
  label?: string;
  className?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  googleApiKey: string | undefined;
  // eslint-disable-next-line react/no-unused-prop-types
  googleChannelId: string;
  // eslint-disable-next-line react/no-unused-prop-types
  region: string;
  // eslint-disable-next-line react/no-unused-prop-types
  language: string;
  enableGeoLocation?: boolean;
  onGeoLocationError?: (error: GeolocationError) => void;
  onChange?: (value: string) => void;
  onError?: () => void;
  onValidAddress?: (value: ValidAddress) => void;
}

export const generateGoogleMapsAPIUrl = ({
  googleApiKey,
  googleChannelId,
  region,
  language,
}: LocationPickerProps): string => {
  return `https://maps.googleapis.com/maps/api/js?v=3.41&libraries=geometry,places&key=${googleApiKey}&channel=${googleChannelId}&region=${region}&language=${language}`;
};

export { ValidAddress };

const LocationPicker: React.FunctionComponent<LocationPickerProps> = (
  props: LocationPickerProps,
): React.ReactElement => {
  const [value, setValue] = React.useState<string>('');
  const [suggestions, setSuggestions] = React.useState<Suggestion[] | null>(null);
  const [googleAutoCompleteService, setGoogleAutoCompleteService] =
    React.useState<google.maps.places.AutocompleteService>();
  const [googleGeocoderService, setGoogleGeocoderService] =
    React.useState<google.maps.Geocoder | null>(null);
  const [isLoadingGeoLocation, setIsLoadingGeoLocation] = React.useState<boolean>(false);

  React.useEffect(() => {
    // Set initial value if given
    const { value: propsValue } = props;
    if (propsValue) setValue(propsValue);
  }, []);

  const handleClick = (suggestion: Suggestion): void => {
    const initUpdate = (updatedValue: string) => {
      setValue(updatedValue);
      setSuggestions(null);
      const { onChange } = props;
      if (onChange) {
        onChange(updatedValue);
      }
    };
    if (googleGeocoderService) {
      // eslint-disable-next-line no-void
      void googleGeocoderService.geocode({ placeId: suggestion.place_id }, (place): void => {
        if (place === null) {
          initUpdate(suggestion.description);
          return;
        }
        const { onValidAddress } = props;
        if (onValidAddress) {
          onValidAddress(destructureAddress(place));
        }
        if (place && place[0]) initUpdate(place[0].formatted_address);
        else initUpdate(suggestion.description);
      });
    } else initUpdate(suggestion.description);
  };

  const handleSuggestion = (updatedValue: string): void => {
    if (googleAutoCompleteService && updatedValue && updatedValue.length > 0) {
      // eslint-disable-next-line no-void
      void googleAutoCompleteService.getPlacePredictions(
        { input: updatedValue },
        (newSuggestions: Suggestion[] | null, status: google.maps.places.PlacesServiceStatus) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            setSuggestions(newSuggestions);
          } else setSuggestions(null);
        },
      );
    } else {
      setSuggestions(null);
    }
  };

  const updateAddress = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const searchValue = event.currentTarget.value;
    setValue(searchValue);
    handleSuggestion(searchValue);

    // Send invalid address if user manually typed it
    const { onValidAddress } = props;
    if (onValidAddress) {
      onValidAddress(destructureAddress([]));
      const { onChange } = props;
      if (onChange) {
        onChange(searchValue);
      }
    }
  };

  const handleGeoLocationSuccess = (position: Geolocation): void => {
    setIsLoadingGeoLocation(false);
    let currentPosition;
    if (typeof google !== 'undefined') {
      const geocoder = new google.maps.Geocoder();
      const latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

      const { onValidAddress, onChange } = props;

      // eslint-disable-next-line no-void
      void geocoder.geocode({ location: latlng }, (gmapsPosition) => {
        if (gmapsPosition === null) {
          setSuggestions(null);
          return;
        }

        if (onValidAddress) {
          onValidAddress(destructureAddress(gmapsPosition));
        }
        if (gmapsPosition && gmapsPosition[0]) {
          currentPosition = gmapsPosition[0].formatted_address;
        } else {
          currentPosition = latlng;
        }
        setValue(currentPosition.toString());

        if (onChange) {
          onChange(currentPosition.toString());
        }
        setSuggestions(null);
      });
      // eslint-disable-next-line react/destructuring-assignment
    } else if (props.onError) {
      // eslint-disable-next-line react/destructuring-assignment
      props.onError();
    }
  };

  const handleGeoLocationFailure = (error: GeolocationError): void => {
    setIsLoadingGeoLocation(false);
    // eslint-disable-next-line react/destructuring-assignment
    if (props.onGeoLocationError) {
      // eslint-disable-next-line react/destructuring-assignment
      props.onGeoLocationError(error);
    }
  };

  const handleGeoLocation = (): void => {
    setIsLoadingGeoLocation(true);
    navigator.geolocation.getCurrentPosition(handleGeoLocationSuccess, handleGeoLocationFailure);
  };

  // eslint-disable-next-line react/destructuring-assignment
  const handleError = (): void => props.onError && props.onError();
  const handleBlur = (): void => setSuggestions(null);
  const handleFocus = (): void => handleSuggestion(value);

  const initAutoComplete = (): void => {
    if (typeof google !== 'undefined') {
      setGoogleGeocoderService(new google.maps.Geocoder());
      setGoogleAutoCompleteService(new google.maps.places.AutocompleteService());
    } else handleError();
  };

  const { className, label, enableGeoLocation } = props;

  return (
    <LocationPickerWrapper className={className || ''}>
      <SearchWrapper>
        <StyledSearchIcon />
        <StyledTextField
          label={label || ''}
          value={value}
          onChange={updateAddress}
          inputId="location-picker-id"
          onBlur={handleBlur}
          onFocus={handleFocus}
          required
        />
        <LocationPickerSuggestionsWrapper
          suggestions={suggestions}
          query={value}
          handleClick={handleClick}
        />
      </SearchWrapper>
      <AsyncScriptLoader
        src={generateGoogleMapsAPIUrl(props)}
        onLoadSuccess={initAutoComplete}
        onLoadError={handleError}
      />
      {enableGeoLocation && googleGeocoderService && (
        <GeolocationButton onClick={handleGeoLocation} clickable={!isLoadingGeoLocation}>
          {isLoadingGeoLocation ? <Loader size="small" /> : <StyledGpsIcon />}
        </GeolocationButton>
      )}
    </LocationPickerWrapper>
  );
};

export default LocationPicker;
