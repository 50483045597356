import React from 'react';
import { useContent } from '@oneaudi/feature-app-utils';

import { AuthContext } from '@oneaudi/myaudi-kit-utils';
import { Content } from '../EditorContentTypes';
import { Context } from '../context';
import { fetchOnboardingStatus, UnavailableData } from '../services/onboarding-status';
import * as pkg from '../../../package.json';
import { useTrackingManager } from '../context/useTrackingManager';

import { PersonalisedMedia } from './CompontentTypes';
import { DealerEntryTeaser } from './dealer-entry-teaser';
import { useEnvConfig } from '../hooks/useEnvConfig';

export const App = (): JSX.Element | null => {
  const trackingManager = useTrackingManager();

  const contentTitle = useContent<Content>()?.defaultContent.texts.title;
  const { localeService } = React.useContext(Context);
  const { bffUrl } = useEnvConfig();
  const Auth = React.useContext(AuthContext);
  const [isAuthenticated, setAuthenticated] = React.useState<boolean>(false);
  const [renderTeaser, SetRenderTeaser] = React.useState<boolean>(false);
  const [personalisedMedia, setPersonalisedMedia] = React.useState<PersonalisedMedia>();
  const [isFailed, setIsFailed] = React.useState<boolean>(false);
  const [isTeaserVisible, setTeaserVisible] = React.useState<boolean>(false);

  const _bootstrap = async () => {
    return (
      !isAuthenticated &&
      Auth.isAuthenticated().then(
        async (authenticated: boolean | ((prevState: boolean) => boolean)) => {
          setAuthenticated(authenticated);
          const accessToken = await Auth.getAccessToken();
          const locale = `${localeService.language}-${localeService.countryCode}`;
          const response = await fetchOnboardingStatus(accessToken, bffUrl, locale);

          const {
            onboardingStatus: { hasAddedVehicle, hasSelectedPartner },
            media,
            unavailableData,
          } = response;

          const favoriteVehicleFrontView = media?.favoriteVehicleFrontView;
          const defaultVehicleFrontView = media?.defaultVehicleFrontView;
          const vehicleExteriorColor = media?.vehicleExteriorColor;

          // filter out optional unavailable data from mandatory data.
          const unavailableRequiredData = unavailableData.filter(
            (x) =>
              x !== UnavailableData.vvdaCore &&
              x !== UnavailableData.vvdaRenderpictures &&
              x !== UnavailableData.pvds,
          );

          if (unavailableRequiredData.length > 0) {
            setIsFailed(true);
          } else if (hasAddedVehicle && !hasSelectedPartner) {
            const hasPersonalisedMedia =
              (defaultVehicleFrontView || favoriteVehicleFrontView) && vehicleExteriorColor;

            // only setMedia state if we have successfully got favorite/default media from BFF
            if (hasPersonalisedMedia) {
              // can be either favorite or default vehicle image.
              const vehicleFrontView = favoriteVehicleFrontView || defaultVehicleFrontView;

              setPersonalisedMedia({
                vehicleFrontView,
                vehicleExteriorColor,
              });
            }
            /**
             * teaser should be rendered at this point because the conditions are met
             * already, it can be with or without personalised media, depends on the
             * availability of vvda_core and vvda_renderpictures backends.
             */
            SetRenderTeaser(true);
          }
        },
      )
    );
  };

  React.useEffect(() => {
    // eslint-disable-next-line no-void
    void _bootstrap();
  }, []);

  const linkTracking = React.useCallback((href: string, text: string, clickId: string): void => {
    trackingManager.trackClick(href, text, clickId, contentTitle || '');
  }, []);

  React.useEffect(() => {
    if (isAuthenticated && renderTeaser && !isFailed) {
      setTeaserVisible(true);
      trackingManager.trackReady(pkg.version, contentTitle || '');
    }
  }, [isAuthenticated, renderTeaser, isFailed]);

  if (!isTeaserVisible) return null;

  return <DealerEntryTeaser linkTracking={linkTracking} personalisedMedia={personalisedMedia} />;
};
